import React from 'react';
import ReactDOM from 'react-dom';
import worker from 'mocks/browser';
import AppSetting from 'settings';
import App from './App';
import reportWebVitals from './reportWebVitals';

const renderApp = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <React.StrictMode>
      <AppSetting>
        <App />
      </AppSetting>
    </React.StrictMode>,
    document.querySelector('#root'),
  );

if (process.env.NODE_ENV === 'development') {
  // If you end up with a component that fetches on mount,
  // this ensures that `msw` has started and prevents
  // a possible race condition for some users/browsers
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  // import('./mocks/browser').then((worker) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  worker.start().then(() => renderApp());
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
} else {
  renderApp();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
