export const TECH = 'Tech';
export const EVENT = 'Event';
export const ASSIGNING_ON = 'Assigning on';
export const SLOTS_AVAILABLE = 'Slots available';
export const QUEUED = 'queued';
export const PICK_A_SLOT = 'Pick a Slot';
export const SELECT_TECH_TO_ASSIGN_EVENT = 'Select tech to assign event';
export const ASSIGNED_TO = 'Assigned to';
export const FOR_TODAY = 'for today';
export const NO_EVENT_FOUND = 'No Event Found !';
export const ASSIGNED = 'Assigned';
export const EVENT_NUMBER = 'Event Number';
export const EVENT_LOCATION_AND_DISTANCE = 'Event Location and distance';
export const TIME_AND_DATE = 'Time & Date';
export const TRUCK_DISPATCH = 'Truck Dispatch';
export const NAME = 'Name';
export const SKILLS = 'Skills';
export const ACTION = 'Action';
export const SERVICES = 'Services';
export const ASSIGN_TECHNICIAN = 'Assign Technician';
export const TECHNICIANS = 'Technicians';
export const MARKER_TYPE = 'Marker Type';

// time/date formats
export const EVENT_DURATION_TIME_FORMAT = 'hh:mm aa';
export const EVENT_DURATION_DATE_FORMAT = 'd/M/yyyy';

// alert messages
export const TECH_ASSIGNED_SUCCESSFULLY = 'Technician assigned successfully.';
export const EVENT_RE_SCHEDULED_MSG = 'Event re-scheduled successfully.';
export const PLEASE_TRY_AGAIN = 'Something went wrong please try again....';
export const PLEASE_WAIT = 'Please wait....';
export const SCHEDULE_CONFIRMED = 'Event schedule confirmed.';
export const NO_SLOTS_AVAILABLE = 'No slots available';
export const CHANGE_DATE_TO_SELECT_SLOTS =
  'Please change the date and select a slot';
export const ASSIGNED_TO_TECH = 'Assigned to Tech';

// legend content
export const UN_ASSIGNED_EVENTS = 'Unassigned Events';
export const DECLINED_EVENTS = 'Declined Events';
export const TECHNICIAN = 'Technician';
export const SERVICE_CENTER = 'Service Center';
export const REPAIR_EVENTS = 'Repaired Events';
export const SHOW_TECH_NAME = 'Show technician name';

// pusher channel-names
export const TECH_STATUS_AND_SCHEDULE_UPDATES_PUSHER_CHANNEL_NAME =
  'incident-updates.tenant-';
export const SCHEDULE_UPDATE_EVENT_NAME = 'schedule_update';
export const TECH_STATUS_UPDATE_EVENT_NAME = 'tech_status_update';
export const TECH_LOCATION_UPDATE_CHANNEL = 'tech_location';
export const TECH_LOCATION_UPDATE_EVENT = 'tech_locations_';

// segment analytics event names
export const JPB_USER_ACCESSED = 'jpb:user_accessed';
export const JPB_TECH_ASSIGNED = 'jpb:tech_assigned';
export const JPB_DATE_CHANGED = 'jpb:date_changed';
export const JPB_EVENT_RE_SCHEDULED = 'jpb:event_rescheduled';
export const JPB_TECH_FILTERED = 'jpb:tech_filtered';
export const JPB_UNASSIGNED_EVENTS_LOADED = 'jpb:unassigned_events_loaded';
export const JPB_EVENTS_FILTERED = 'jpb:events_filtered';

// query param strings
export const HQ_VIEW = 'hqView';
export const TENANT_ID = 'tenantID';
export const USER_AUTH_TOKEN = 'userAuthToken';

// tech/event statuses
export const REPAIRED = 'repaired';
export const COMPLETED = 'completed';
export const VOIDED = 'voided';
export const REJECTED = 'rejected';
export const DECLINED = 'declined';
export const RE_ASSIGNED = 'reassigned';

export enum JpbViews {
  GANTT = 'gantt',
  MAP = 'map',
}
