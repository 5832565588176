import type { ChildrenProps } from 'types';
import AxiosSetting from './AxiosSetting';
import ReactQuerySetting from './ReactQuerySetting';
import RouterSetting from './RouterSetting';

const AppSetting = ({ children }: ChildrenProps) => (
  <ReactQuerySetting>
    <AxiosSetting>
      <RouterSetting>{children}</RouterSetting>
    </AxiosSetting>
  </ReactQuerySetting>
);

export default AppSetting;
