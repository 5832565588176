/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useEffect } from 'react';
import { userInfo } from 'atoms/technician';
import { useAtom } from 'jotai';
import { segmentAnalytics, standardMetaData } from 'atoms/analytics';
import Routes from 'routes';
import { JPB_USER_ACCESSED, HQ_VIEW, JpbViews } from 'consts/strings';
import { useUserInfo } from 'queries/technicians.query';

import './index.less';

const App = () => {
  const [, setAnalytics] = useAtom(segmentAnalytics);
  const [, setMetaData] = useAtom(standardMetaData);
  const [, setUserInfo] = useAtom(userInfo);
  const queryParams = new URLSearchParams(window.location.search);
  const isHqUser = queryParams.get(HQ_VIEW);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: userData } = useUserInfo();
  useEffect(() => {
    if (userData) setUserInfo(userData.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    const loadAnalytics = async () => {
      if (userData) {
        const [analytics] = await AnalyticsBrowser.load({
          writeKey: process.env.REACT_APP_SEGMENT_ANALYTICS_WRITE_KEY as string,
        });
        setAnalytics(analytics);
        const metaData = {
          jpbView: JpbViews.GANTT,
          serviceCenter: `${userData.data.tenant_info.name} [${userData.data.tenant_info.subdomain}]`,
          hqUser: isHqUser,
        };
        setMetaData(metaData);
        analytics.identify(`${userData.data.id}`, metaData);
        analytics.page(JPB_USER_ACCESSED, metaData);
      }
    };
    loadAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  return <Routes />;
};

export default App;
