import { Row, Col } from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type { ChildrenProps } from 'types';
import './applayout.less';

const AppLayout = ({ children }: ChildrenProps) => (
  <Row style={{ height: '100vh' }}>
    <Col span={24}>
      <Row style={{ background: '#E5E5E5' }}>{children}</Row>
    </Col>
    <ToastContainer position="top-center" />
  </Row>
);

export default AppLayout;
