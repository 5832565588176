/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { rest } from 'msw';
import {
  ASSIGN_TECHNICIANS_PATH,
  UN_ASSIGNED_EVENTS_PATH,
  TECHNICIANS_PATH,
  TECHNICIAN_SHIFT_PATH,
  ASSIGNED_EVENTS_PATH,
  CONFIRM_EVENT,
} from 'consts/api';
import records from 'fixtures/dashboard.json';
import technicians from 'fixtures/technicians.json';
import events from 'fixtures/unassignedEvents.json';
import assignedEvents from 'fixtures/assignedEvents.json';
import shifts from 'fixtures/shifts.json';

const handlers = [
  rest.get('/api/dashboard', (req, res, ctx) =>
    res(ctx.status(200), ctx.json(records)),
  ),
  rest.post('/api/users/sign_in', (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        id: 7,
        email: 'vysakh@pipesort.com',
        first_name: 'vysakh',
        company_name: null,
        onboarding_complete: false,
      }),
      ctx.set('Authorization', `Bearer ${process.env.REACT_APP_TOKEN || ''}`),
    ),
  ),
  rest.get(`/api/${TECHNICIANS_PATH}`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(technicians)),
  ),
  rest.get(`/api/${UN_ASSIGNED_EVENTS_PATH}`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(events)),
  ),
  rest.get(`/api/${ASSIGNED_EVENTS_PATH}`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(assignedEvents)),
  ),
  rest.get(`/api/${TECHNICIAN_SHIFT_PATH}`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(shifts)),
  ),
  rest.post(
    `/api/${ASSIGN_TECHNICIANS_PATH}/:id/technicians/assign`,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          success: true,
          message: 'Technician assigned successfully.',
          data: null,
          meta: null,
        }),
      ),
  ),
  rest.post(
    `/api/${ASSIGN_TECHNICIANS_PATH}/:id/event_schedules/reschedule`,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          success: true,
          message: 'Event re-scheduled successfully.',
          data: null,
          meta: null,
        }),
      ),
  ),
  rest.post(
    `/api/${CONFIRM_EVENT}/:id/event_schedules/confirm`,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json({
          success: true,
          message: 'schedule confirmed',
          data: null,
          meta: null,
        }),
      ),
  ),
];

export default handlers;
