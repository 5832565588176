import { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import AppLayout from 'components/AppLayout';

const Tab = lazy(() => import('components/Tab'));

const AppRoutes = () => (
  <AppLayout>
    <Suspense
      fallback={
        <div className="h-screen bg-white w-full flex flex-row justify-center">
          <Spin tip="Loading..." className="self-center" size="large" />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Tab} />
      </Switch>
    </Suspense>
  </AppLayout>
);

export default AppRoutes;
