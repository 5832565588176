/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import {
  TechnicianShiftType,
  TechnicianType,
  UserType,
} from 'types/technician';
import {
  TECHNICIANS_PATH,
  TECHNICIAN_SHIFT_PATH,
  USER_INFO_PATH,
} from 'consts/api';

export const useTechniciansList = () =>
  useQuery<TechnicianType, Error>(TECHNICIANS_PATH);

export const useTechniciansShift = (start_date: number, end_date: number) =>
  useQuery<TechnicianShiftType, Error>(
    `${TECHNICIAN_SHIFT_PATH}?start_date=${start_date}&end_date=${end_date}`,
  );

export const useUserInfo = () => useQuery<UserType, Error>(USER_INFO_PATH);
