import { rest, setupWorker } from 'msw';
import handlers from './handlers';

const worker = setupWorker(...handlers);

declare global {
  interface Window {
    msw: unknown;
  }
}

// Make the `worker` and `rest` references available globally,
// so they can be accessed in both runtime and test suites.
window.msw = {
  rest,
  worker,
};

export default worker;
