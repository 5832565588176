import { Analytics } from '@segment/analytics-next';
import { atom } from 'jotai';
import { JpbViews } from 'consts/strings';

export type MetaDataType = {
  jpbView: 'gantt' | 'map';
  serviceCenter: string;
  hqUser: string | null;
};

export const segmentAnalytics = atom<Analytics | null>(null);
export const standardMetaData = atom<MetaDataType>({
  jpbView: JpbViews.GANTT,
  serviceCenter: '',
  hqUser: '',
});
